import ScoreValue from "../../types/Score";
import "./Count.scss"

function Count({score, count}: { score: ScoreValue, count: number }) {
    let points = score == ScoreValue.X ? 10 : parseInt(score.toString());
    if (isNaN(points)) {
        points = 0;
    }
    points *= count;
    const classes = ["count"];
    if (count > 0) {
        classes.push("has-count")
    }
    return (<p className={["score-count", score].join(' ')}>* <span className={classes.join(' ')}>{count}</span> = <span className="points">{points}</span></p>)
}

export default Count;