enum Direction {
    LTR = "ltr",
    RTL = "rtl",
}

namespace Direction {
    export function from(value: any): Direction {
        return value == "rtl" ? Direction.RTL : Direction.LTR;
    }
}

export default Direction;