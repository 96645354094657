enum Theme {
    LIGHT = "light",
    DARK = "dark",
}

namespace Theme {
    export function from(value: any): Theme {
        if (value == "dark") {
            return Theme.DARK;
        }
        if (value == "light") {
            return Theme.LIGHT;
        }

        window.matchMedia = window.matchMedia || (() => ({matches: false}));
        return (window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT);
    }
}

export default Theme;