import {ReactNode, useState} from "react";
import {PartialBy} from "../../types/Lib";
import './ConfirmButton.scss';
import ButtonList from "./ButtonList";

interface ConfirmButtonProps {
    onConfirm: () => void,
    danger: boolean,
    children: ReactNode | ReactNode[]
}

export default function ConfirmButton({
                                          onConfirm,
                                          danger,
                                          children
                                      }: PartialBy<ConfirmButtonProps, 'danger' | 'children'>) {

    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const className = danger ? "danger" : "";

    if (isConfirming) {
        return (
            <ButtonList>
                <button onClick={() => setIsConfirming(false)}>Cancel</button>
                <button className={className} onClick={() => {
                    setIsConfirming(false);
                    onConfirm()
                }}>{children}</button>
            </ButtonList>
        )
    }
    return (<button className={className} onClick={() => setIsConfirming(true)}>{children}</button>)
}