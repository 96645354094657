enum CourseOfFireType {
    PRESET_1500,
    PRESET_600,
    MATCH_OF_6,
    MATCH_OF_12,
    MATCH_OF_18,
    MATCH_OF_24,
    MATCH1,
    MATCH2,
    MATCH3,
    MATCH4,
    MATCH5A,
    MATCH5B,
}

export const getName = (type: CourseOfFireType): string => {
    switch (type) {
        case CourseOfFireType.PRESET_1500:
            return "Preset: 1500";
        case CourseOfFireType.PRESET_600:
            return "Preset: 600";
        case CourseOfFireType.MATCH_OF_6:
            return "6 shots";
        case CourseOfFireType.MATCH_OF_12:
            return "12 shots";
        case CourseOfFireType.MATCH_OF_18:
            return "18 shots";
        case CourseOfFireType.MATCH_OF_24:
            return "24 shots";
        case CourseOfFireType.MATCH1:
            return "Match 1";
        case CourseOfFireType.MATCH2:
            return "Match 2";
        case CourseOfFireType.MATCH3:
            return "Match 3";
        case CourseOfFireType.MATCH4:
            return "Match 4";
        case CourseOfFireType.MATCH5A:
            return "Match 5 - A";
        case CourseOfFireType.MATCH5B:
            return "Match 5 - B";
    }
}

export const getRoundCount = (type: CourseOfFireType): number => {
    switch (type) {
        case CourseOfFireType.PRESET_1500:
            return 150;
        case CourseOfFireType.PRESET_600:
            return 60;
        case CourseOfFireType.MATCH_OF_6:
            return 6;
        case CourseOfFireType.MATCH_OF_12:
            return 12;
        case CourseOfFireType.MATCH_OF_18:
            return 18;
        case CourseOfFireType.MATCH_OF_24:
            return 24;
        case CourseOfFireType.MATCH1:
            return 24;
        case CourseOfFireType.MATCH2:
            return 18;
        case CourseOfFireType.MATCH3:
            return 24;
        case CourseOfFireType.MATCH4:
            return 24;
        case CourseOfFireType.MATCH5A:
            return 30;
        case CourseOfFireType.MATCH5B:
            return 30;
    }
}

export default CourseOfFireType;
