import './Scoreboard.scss'
import MatchState from "../../../types/MatchState";
import Score from "../../../types/Score";
import ScoreValue from "../../../types/Score";
import HitCell from "./HitCell";
import ScoreCell from "./ScoreCell";
import Classification from "../../../types/Classification";
import React from 'react';

const scoreToPoints = (score: ScoreValue) => {
    let points = score == ScoreValue.X ? 10 : parseInt(score.toString());
    if (isNaN(points)) {
        points = 0;
    }
    return points;
}

export default function Scoreboard({matches}: { matches: MatchState[] }) {
    const headers = [(
        <th key="top-header-empty">{/*This cell is empty because the first column is score values*/}</th>)];
    matches.forEach((match, index) => {
        headers.push((<th key={index + "matchname"} colSpan={2}>{match.name}</th>));
    });
    headers.push((<th key="totals" colSpan={2}>Totals</th>));
    const subHeaders = [(
        <th key="sub-header-empty">{/*This cell is empty because the first column is score values*/}</th>)];
    matches.forEach((match, index) => {
        subHeaders.push((<React.Fragment
            key={index + "match"}><HitCell>hits</HitCell><ScoreCell>score</ScoreCell></React.Fragment>));
    });
    subHeaders.push((
        <React.Fragment key="totals-subheader"><HitCell>hits</HitCell><ScoreCell>score</ScoreCell></React.Fragment>));

    const rows = [
        Score.X,
        Score.Ten,
        Score.Nine,
        Score.Eight,
        Score.Seven,
        Score.Zero,
        Score.Miss,
    ].map((scoreValue, index) => {
        const points = scoreToPoints(scoreValue);
        const totalHits = matches.reduce((carry, match) => carry + (match.scores.get(scoreValue) ?? 0), 0);

        return (<tr key={index + "row"}>
            <td key={index + "score-value"}>{scoreValue}</td>
            {matches.map((match, index) => (
                    <React.Fragment key={index + "hit-score"}>
                        <HitCell>{match.scores.get(scoreValue)}</HitCell>
                        <ScoreCell>{(match.scores.get(scoreValue) ?? 0) * 10}</ScoreCell>
                    </React.Fragment>
                )
            )
            }

            <HitCell key={index + "hit-total"}>{totalHits}</HitCell>
            <ScoreCell key={index + "score-total"}>{totalHits * points}</ScoreCell>
        </tr>)
    });

    const totalRow = [(<td key="total-row-label">Totals</td>)];
    let grandTotalHits = 0;
    let grandTotalScore = 0;
    matches.forEach((match, index) => {
        const scoreIterator = Array.from(match.scores.entries());
        const totalHits = scoreIterator.reduce((carry, [, num]) => carry + num, 0);
        const totalScore = scoreIterator.reduce((carry, [value, quantity]) => carry + scoreToPoints(value) * quantity, 0);
        grandTotalHits += totalHits;
        grandTotalScore += totalScore;

        totalRow.push(<React.Fragment
            key={index + "totals"}><HitCell>{totalHits}</HitCell><ScoreCell>{totalScore}</ScoreCell></React.Fragment>);
    })
    const maxScore = matches.reduce((carry, match) => carry + match.roundCount, 0) * 10;
    const numX = matches.reduce((carry, match) => carry + (match.scores.get(ScoreValue.X) ?? 0), 0);
    const percent = grandTotalScore / maxScore * 100;
    const classification = Classification.fromScore(grandTotalScore, maxScore);
    totalRow.push((<React.Fragment key="grand-totals"><HitCell><span
        className="strong">{grandTotalHits}</span></HitCell><ScoreCell><span
        className="strong">{grandTotalScore}-x{numX} / {maxScore} = {percent.toFixed(2)}% ({classification.name})</span></ScoreCell></React.Fragment>));

    return (
        <section className="subsection scoreboard-container">
            <table className="scoreboard">
                <thead>
                <tr>
                    {headers}
                </tr>
                <tr>
                    {subHeaders}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
                <tbody>
                <tr>{totalRow}</tr>
                </tbody>
            </table>
        </section>
    )
}