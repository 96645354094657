const data = [
    {name: 'Grand Master', predicate: ((percentage: number) => percentage >= 98.4)},
    {name: 'Distinguished Master', predicate: ((percentage: number) => percentage >= 97.4 && percentage < 98.4)},
    {name: 'Master', predicate: ((percentage: number) => percentage >= 96 && percentage < 97.4)},
    {name: 'Expert', predicate: ((percentage: number) => percentage >= 92 && percentage < 96)},
    {name: 'Sharpshooter', predicate: ((percentage: number) => percentage >= 86 && percentage < 92)},
    {name: 'Marksman', predicate: ((percentage: number) => percentage < 86)},
];

class Classification {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }

    public static fromScore(score: number, total: number): Classification {
        const percentage = score / total * 100;
        const found = data.find((item) => item.predicate(percentage))?.name;
        return new Classification(found ?? 'Marksman');
    }
}

export default Classification;