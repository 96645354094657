import React, {useState} from 'react';
import logo from './revolver.svg';
import './App.scss';
import CourseOfFireSelect from "./component/CourseOfFireSelect/CourseOfFireSelect";
import CourseOfFireType from "./types/CourseOfFire";
import Match from "./component/Match/Match";
import Theme from "./types/Theme";
import MatchState, {newMatchState} from "./types/MatchState";
import Direction from "./types/Direction";
import Preferences from "./component/Preferences/Preferences";
import Cookies from "js-cookie";
import MatchNavigation from "./component/Match/MatchNavigation";
import TotalCounts from "./component/Counts/TotalCounts";
import Scoreboard from "./component/Score/Scoreboard/Scoreboard";
import ConfirmButton from "./component/Buttons/ConfirmButton";
import ButtonList from "./component/Buttons/ButtonList";
import {getStoredMatchData, saveMatches} from "./SaveMatches";

type MatchList = MatchState[];

function App() {
    const defaultTheme: Theme = Theme.from(Cookies.get("theme"))
    const defaultDirection: Direction = Direction.from(Cookies.get("direction"));

    const [theme, useTheme] = useState<Theme>(defaultTheme);
    const setTheme = (theme: Theme) => {
        Cookies.set("theme", theme);
        useTheme(theme);
    }
    const [direction, useDirection] = useState<Direction>(defaultDirection);
    const setDirection = (direction: Direction) => {
        Cookies.set("direction", direction);
        useDirection(direction);
    }

    const storedData = getStoredMatchData();
    const defaultCurrentMatchIndex = storedData.currentMatchIndex ?? storedData.matches.length - 1;

    const [inProgress, setInProgress] = useState<boolean>(true);
    const [matches, setMatches] = useState<MatchList>(storedData.matches);
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(defaultCurrentMatchIndex);
    const currentMatch: MatchState | null = matches[currentMatchIndex] ?? null;

    return (
        <div className={["App", direction].join(' ')} data-theme={theme}>
            <header className="App-header">
                <div className="left">
                    <div className="App-logo">
                        <h1>1500</h1>
                        <img src={logo} className="App-logo" alt="logo"/>
                    </div>
                </div>
                <div className="right">
                    <Preferences theme={theme} onTheme={setTheme} direction={direction} onDirection={setDirection}/>
                </div>
            </header>
            <section className="App-body">
                <CourseOfFireSelect
                    onSelect={(type) => {
                        const newMatches = [...matches, newMatchState({type})]
                        const newMatchIndex = newMatches.length - 1;
                        saveMatches(newMatches, newMatchIndex);
                        setMatches(newMatches);
                        setCurrentMatchIndex(newMatchIndex);
                    }}
                    onSetPreset={(type) => {
                        let matches: MatchList;
                        switch (type) {
                            case CourseOfFireType.PRESET_1500:
                                matches = [CourseOfFireType.MATCH1, CourseOfFireType.MATCH2, CourseOfFireType.MATCH3, CourseOfFireType.MATCH4, CourseOfFireType.MATCH5A, CourseOfFireType.MATCH5B].map((type) => ({type})).map(newMatchState);
                                break;
                            case CourseOfFireType.PRESET_600:
                                matches = [CourseOfFireType.MATCH5A, CourseOfFireType.MATCH5B].map((type) => ({type})).map(newMatchState);
                                break;
                            default:
                                throw new Error('Uh-oh, not all course of fire presets are handled.');
                        }

                        saveMatches(matches, 0);
                        setMatches(matches);
                        setCurrentMatchIndex(0);
                    }}
                />
            </section>
            <section className="App-body">
                <MatchNavigation matches={matches} currentIndex={currentMatchIndex}
                                 onSelect={(index) => {
                                     setCurrentMatchIndex(index);
                                     saveMatches(matches, index);
                                 }}>
                    <ButtonList>
                        <ConfirmButton danger={true} onConfirm={() => {
                            const matches: MatchList = [];
                            setMatches(matches);
                            setCurrentMatchIndex(0);
                            saveMatches(matches, 0);
                            setInProgress(true);
                        }}>Clear All
                        </ConfirmButton>
                        <button
                            onClick={() => setInProgress(!inProgress)}>{inProgress ? 'Finish' : 'Return to Scoring'}</button>
                    </ButtonList>
                </MatchNavigation>
            </section>
            <section className="App-body">
                <section>
                    {currentMatch && inProgress ? (
                        <>
                            <Match state={currentMatch} onChange={match => {
                                matches[currentMatchIndex] = match;
                                setMatches([...matches]);
                                saveMatches(matches, currentMatchIndex);
                            }} onDelete={() => {
                                const newMatchIndex = Math.max(currentMatchIndex - 1, 0);
                                let newMatches: MatchList;
                                if (matches.length > 1) {
                                    newMatches = [...matches];
                                    newMatches.splice(currentMatchIndex, 1);
                                } else {
                                    newMatches = [];
                                }
                                setCurrentMatchIndex(newMatchIndex);
                                saveMatches(newMatches, newMatchIndex);
                                setMatches(newMatches);
                            }}/>
                            <section className="subsection">
                                <TotalCounts matches={matches}/>
                            </section>
                        </>
                    ) : ''}
                </section>
            </section>
            {inProgress ? '' : (
                <section className="App-body">
                    <Scoreboard matches={matches}/>
                </section>
            )}
        </div>
    );
}

export default App;
