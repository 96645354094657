enum ScoreValue {
    Miss = "miss",
    Zero = 0,
    Seven = 7,
    Eight = 8,
    Nine = 9,
    Ten = 10,
    X = "x",
}

export default ScoreValue;
