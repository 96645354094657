import CourseOfFireType, {getName, getRoundCount} from "./CourseOfFire";
import MatchScores from "./MatchScores";
import {PartialBy} from './Lib';

type MatchState = { name: string, type: CourseOfFireType, roundCount: number, scores: MatchScores };

interface NewMatchParams {
    type: CourseOfFireType,
    name: string,
    roundCount: number,
}

type NewMatchInput = PartialBy<NewMatchParams, 'name' | 'roundCount'>

export const newMatchState = ({type, name, roundCount}: NewMatchInput): MatchState => {
    if (!name) {
        name = getName(type);
    }
    if (!roundCount) {
        roundCount = getRoundCount(type);
    }
    return ({name, type, roundCount, scores: new Map()})
}

export default MatchState;