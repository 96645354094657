import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import DirectionSwitcher from "../DirectionSwitcher/DirectionSwitcher";
import React, {useState} from "react";
import Theme from "../../types/Theme";
import Direction from "../../types/Direction";
import "./Preferences.scss";

function Preferences({theme, onTheme, direction, onDirection}: {
    theme: Theme,
    direction: Direction,
    onTheme: (theme: Theme) => void,
    onDirection: (direction: Direction) => void
}) {
    const [open, setOpen] = useState<boolean>(false);
    const className = open ? "open" : "closed";
    const toggleOpen = () => setOpen(!open);

    return (<div className={["preferences", className].join(' ')}>
        <h4 onClick={toggleOpen}>Preferences <span className="chevron">❱</span></h4>
        <div className={[className, "preferences-container"].join(' ')}>
            <ThemeSwitcher theme={theme} onChange={onTheme}/>
            <DirectionSwitcher direction={direction} onChange={onDirection}/>
        </div>
    </div>);
}

export default Preferences;