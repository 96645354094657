import Theme from "../../types/Theme";
import "./ThemeSwitcher.scss";
import "../Switcher/Switcher.scss";

function ThemeSwitcher({theme, onChange}: { theme: Theme, onChange: (theme: Theme) => void }) {
    return (
        <div className="theme switcher">
            <dl>
                <dt><p>Colour Scheme</p></dt>
                <dd>
                    <div><label className="switcher-item">Light
                        <input name="theme" type="radio" value={Theme.LIGHT} onClick={() => onChange(Theme.LIGHT)}
                               defaultChecked={theme == Theme.LIGHT}/>
                    </label></div>
                    <div><label className="switcher-item">Dark
                        <input name="theme" type="radio" value={Theme.DARK} onClick={() => onChange(Theme.DARK)}
                               defaultChecked={theme == Theme.DARK}/>
                    </label></div>
                </dd>
            </dl>
        </div>
    )
}

export default ThemeSwitcher;