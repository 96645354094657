import Direction from "../../types/Direction";
import "../Switcher/Switcher.scss";
import "./DirectionSwitcher.scss";

function DirectionSwitcher({direction, onChange}: { direction: Direction, onChange: (direction: Direction) => void }) {
    return (
        <div className="direction switcher">
            <dl>
                <dt><p>Handedness</p></dt>
                <dd>
                    <div>
                        <label className="switcher-item">Left
                            <input name="direction" type="radio" value={Direction.RTL}
                                   onClick={() => onChange(Direction.RTL)}
                                   defaultChecked={direction == Direction.RTL}/>
                        </label>
                    </div>
                    <div>
                    <label className="switcher-item">Right
                        <input name="direction" type="radio" value={Direction.LTR}
                               onClick={() => onChange(Direction.LTR)}
                               defaultChecked={direction == Direction.LTR}/>
                    </label>
                    </div>
                </dd>
            </dl>
        </div>
    )
}

export default DirectionSwitcher;

