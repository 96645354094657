import Counts from "../Counts/Counts";
import React, {useEffect, useState} from "react";
import Score from "../../types/Score";
import ScoreComponent from "../Score/Score";
import "./Match.scss";
import MatchScores from "../../types/MatchScores";
import MatchState from "../../types/MatchState";
import ScoreValue from "../../types/Score";
import ConfirmButton from "../Buttons/ConfirmButton";
import ButtonList from "../Buttons/ButtonList";

const defaultScores = (): MatchScores =>
    new Map([
        [Score.Miss, 0],
        [Score.Zero, 0],
        [Score.Seven, 0],
        [Score.Eight, 0],
        [Score.Nine, 0],
        [Score.Ten, 0],
        [Score.X, 0],
    ]);

function Match({state, onChange, onDelete}: {
    state: MatchState,
    onChange: (match: MatchState) => void,
    onDelete: () => void
}) {
    const [scores, setScores] = useState<MatchScores>(state.scores ?? defaultScores());

    useEffect(() => {
        setScores(state.scores)
    }, [state])

    const scoreUpdater = (adjuster: (score: ScoreValue) => void) => {
        return (score: Score) => {
            adjuster(score);
            // Also update the match itself, which needs to remember the score.
            state.scores = scores;
            setScores(new Map(scores));
            onChange(state);
        }
    }

    const reset = () => {
        const scores = defaultScores();
        state.scores = scores;
        setScores(scores);
        onChange(state);
    };

    const addScore = scoreUpdater(score => scores.set(score, (scores.get(score) ?? 0) + 1));
    // Subtract a count without going below zero.
    const subtractScore = scoreUpdater(score => scores.set(score, Math.max((scores.get(score) ?? 0) - 1, 0)));

    // The jumbled order puts low scores on the left and high on the right, for 3 columns.
    const widgets = [Score.Miss, Score.Seven, Score.Nine, Score.Zero, Score.Eight, Score.Ten, Score.X].map(
        (value: Score) => (<ScoreComponent key={value} value={value} onAdd={addScore} onSubtract={subtractScore}
                                           count={(scores.get(value) ?? 0)}/>)
    );

    return (
        <section>
            <section className="subsection score-columns">
                {widgets}
            </section>
            <section className="subsection match-counts">
                <Counts scores={scores} expectedCount={state.roundCount}/>
            </section>
            <section className="subsection">
                <ButtonList>
                    <ConfirmButton danger={true}
                                   onConfirm={() => reset()}>Reset</ConfirmButton>
                    <ConfirmButton danger={true} onConfirm={onDelete}>Delete</ConfirmButton>
                </ButtonList>
            </section>
        </section>
    );

}

export default Match;