import MatchState from "../../types/MatchState";
import "./MatchNavigation.scss"
import React, {ReactNode} from "react";
import ButtonList from "../Buttons/ButtonList";

export default function MatchNavigation({matches, currentIndex, onSelect, children}: {
    matches: MatchState[],
    currentIndex: number,
    onSelect: (index: number) => void,
    children: ReactNode | ReactNode[],
}) {

    const matchButtons = matches.map((state, index) => (
        <button key={index}
                className={['match-navigation-select', index === currentIndex ? 'active' : 'inactive'].join(' ')}
                onClick={() => onSelect(index)}>{state.name}</button>
    ));

    const previous = (<button onClick={() => onSelect(currentIndex - 1)}
                              className="match-navigation-select"
                              disabled={!(matches[currentIndex - 1] ?? null)}>&lt;</button>)
    const next = (<button onClick={() => onSelect(currentIndex + 1)}
                          className="match-navigation-select"
                          disabled={!(matches[currentIndex + 1] ?? null)}>&gt;</button>)

    return (
        <div className="match-navigation-widget"><h2>Courses of Fire</h2>
            <div className="match-navigation-selects">
                <ButtonList>
                    {previous}
                    {matchButtons}
                    {next}
                </ButtonList>
            </div>
            <div className="match-navigation-selects">
                {children}
            </div>
        </div>
    )
}