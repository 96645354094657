import ScoreValue from "../../types/Score";
import Score from "../../types/Score";
import './Counts.scss';
import Classification from "../../types/Classification";

function Counts({scores, expectedCount}: { scores: Map<Score, number>, expectedCount: number }) {
    const countsList = Array.from(scores.entries());

    const total = countsList.map(([, v]) => v).reduce((carry, item) => carry + item, 0);

    const className = ((total, expectedCount) => {
        if (total < expectedCount) {
            return "needs-more";
        }
        if (total > expectedCount) {
            return "needs-fewer";
        }
        return "just-right";
    })(total, expectedCount);

    const totalPoints = countsList.reduce((carry, item) => {
        const [score, count] = item;
        let points = score == ScoreValue.X ? 10 : parseInt(score.toString());
        if (isNaN(points)) {
            points = 0;
        }
        return carry + (points * count);
    }, 0)

    const num_x = (scores.get(ScoreValue.X) ?? 0);
    const xString = num_x === 0 ? '' : `-x${num_x}`;
    const maxPoints = expectedCount * 10;
    const percent = (totalPoints / maxPoints * 100).toFixed(2);
    const classification = Classification.fromScore(totalPoints, maxPoints);

    return (<div>
        <div className={["total", className].join(' ')}>Counted {total} / {expectedCount}
            <br/>
            Scored {totalPoints}{xString} / {maxPoints} = {percent}% ({classification.name})
        </div>
    </div>);
}

export default Counts;