import CourseOfFireType, {getName} from "../../types/CourseOfFire";
import "./CourseOfFireSelect.scss";

function CourseOfFireSelect({onSelect, onSetPreset}: {
    onSelect: (choice: CourseOfFireType) => void,
    onSetPreset: (choice: CourseOfFireType) => void,
}) {

    const makeButton = (callback: (type: CourseOfFireType) => void) => (type: CourseOfFireType) => (
        <button key={type} className="course-of-fire-select" onClick={() => callback(type)}>{getName(type)}</button>);

    const presets = [CourseOfFireType.PRESET_1500, CourseOfFireType.PRESET_600].map(makeButton(onSetPreset));
    const inputs = [CourseOfFireType.MATCH_OF_6, CourseOfFireType.MATCH_OF_12, CourseOfFireType.MATCH_OF_18, CourseOfFireType.MATCH_OF_24].map(makeButton(onSelect));

    return (<div className="course-of-fire-widget"><h2>Start Course of Fire</h2>
        <div className="course-of-fire-selects">{presets}{inputs}</div>
    </div>);
}

export default CourseOfFireSelect;