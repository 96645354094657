import './Counts.scss';
import MatchState from "../../types/MatchState";
import MatchScores from "../../types/MatchScores";
import Counts from "./Counts";
import {useEffect, useState} from "react";
import Score from "../../types/Score";

function TotalCounts({matches}: { matches: MatchState[] }) {
    const [scores, setScores] = useState<Map<Score, number>>(new Map());
    const [expectedCount, setExpectedCount] = useState<number>(0);

    useEffect(() => {
        const scores = matches.reduce((carry: MatchScores, match) => {
            // Add each match's scores to the total.
            Array.from(match.scores.entries()).forEach(([value, quantity]) => {
                carry.set(value, (carry.get(value) ?? 0) + quantity);
            });
            return carry;
        }, new Map());

        const expectedCount = matches.reduce((carry, match) => carry + match.roundCount, 0);

        setScores(scores);
        setExpectedCount(expectedCount);
    }, [matches]);

    return (<div>
            <h2>Total</h2>
            <Counts scores={scores} expectedCount={expectedCount}/>
        </div>
    );
}

export default TotalCounts;