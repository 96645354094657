import Score from "../../types/Score";
import './Score.scss';
import Count from "../Count/Count";
import React from "react";

function ScoreComponent({value, onAdd, onSubtract, count}: {
    value: Score,
    onAdd: (score: Score) => void,
    onSubtract: (score: Score) => void,
    count: number,
}) {
    const handleAdd = () => {
        onAdd(value);
    };
    const handleSubtract = () => {
        onSubtract(value);
    };

    return (
        <div className={["score-controls", value].join(' ')}>
            <div className="score-column">
                <span className="value"><span>{value}</span></span>
            </div>
            <div className="score-column">
                <Count score={value} count={count}/>
            </div>
            <div className="score-column">
                <button className="score-control subtract" value={value} onClick={handleSubtract}>-</button>
                <button className="score-control add" value={value} onClick={handleAdd}>+</button>
            </div>
        </div>
    )
}

export default ScoreComponent;