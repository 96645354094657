import MatchState from "./types/MatchState";

const reviver = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
};

const replacer = (key: string, value: any) => {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: [...value],

        }
    }
    return value;
}

interface StoredMatchData {
    matches: MatchState[],
    currentMatchIndex: number,
}

const getSerialized = (): string => {
    const defaultSerialized = '{ "matches": [], "currentMatchIndex": 0}';
    let serializedData = localStorage.getItem('match-data');
    if (!serializedData) {
        localStorage.setItem('match-data', defaultSerialized);
        serializedData = localStorage.getItem('match-data');
    }
    return serializedData as string;
}

export const saveMatches = (matches: MatchState[], currentMatchIndex: number) => {
    localStorage.setItem('match-data', JSON.stringify({
        matches: matches,
        currentMatchIndex: currentMatchIndex,
    }, replacer));
}

export const getStoredMatchData = (): StoredMatchData => {
    // If parsing fails, remove the stored data and use the default values.
    let storedData: StoredMatchData;
    try {
        storedData = JSON.parse(getSerialized(), reviver);
    } catch (e) {
        if (!(e instanceof SyntaxError)) {
            throw e;
        }
        localStorage.removeItem('match-data');
        storedData = JSON.parse(getSerialized(), reviver);
    }

    storedData.matches = storedData.matches.map(match => {
        match.scores = new Map(match.scores);
        return match;
    });

    return storedData;
}
